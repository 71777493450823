<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-start"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_01.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de ruínas de casas soterradas pela lama até parte da altura
            das paredes. Observa-se que os terrenos foram destruídos e
            descaracterizados. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Situação depois do rompimento da barragem de Fundão"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A comunidade tricentenária de Bento Rodrigues situava-se em
                local em que, a uma distância de 5km, foram construídas nos anos
                de 1970 as barragens de Fundão e Santarém, operadas pela empresa
                mineradora Samarco, controlada pela Vale e pela BHP Billiton.
              </p>
              <p>
                O enorme volume de rejeitos proveniente do rompimento da
                barragem destruiu tanto a área urbana quanto a rural de Bento em
                um primeiro momento. Posteriormente, parte da comunidade foi
                submersa pelo lago do dique S4, incluindo terrenos anteriormente
                soterrados, vias, além de estruturas remanescentes do período
                colonial.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A comunidade de Bento Rodrigues, devido à sua proximidade com a
                barragem, foi a primeira a receber o impacto da onda de
                rejeitos. A violência foi tão forte que a pequena comunidade
                desapareceu soterrada pela lama, e com ela, parte de sua
                história.
              </p>
              <p>
                A paisagem resultante foi a de um mar de lama em meio ao qual
                restaram paredes de casas e da escola municipal, em parte
                soterradas e tingidas com o barro do rejeito, sem telhado.
                Algumas peças da cobertura permaneceram em parte das
                construções, onde ficaram dependurados móveis, cobertas, entre
                outros pertences dos moradores. Até mesmo fogões e veículos
                foram parar no telhado. A lama arrastou o que havia nas casas,
                além de parte delas próprias. Os terrenos de maior altitude não
                foram alcançados pelo rejeito, mas sucumbiram em uma comunidade
                que foi devastada. Com o passar dos anos, a vegetação invasora
                foi encobrindo parte das ruínas e na região próxima ao lago do
                Dique S4, onde a lama destruiu e soterrou o que ali havia,
                visualiza-se a água do lago em meio a terrenos com gramíneas,
                tendo desaparecido qualquer sinal da ocupação anterior.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-end"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_02.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da área de Bento Rodrigues alagada pelo Dique S4. Foto: Flora
            Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_03.png"
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da ruína de uma casa situada na Rua Dona Olinda, onde se
            observa a lama de rejeitos coberta com gramíneas. Foto: Paula
            Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_04.png"
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de escombros de construção sobre a lama acumulada em um
            terreno da comunidade, com a placa sinalizando que os terrenos
            pertencem aos moradores, apesar do território ter sido destruído.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_0202.png"
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de trecho da Rua Ouro Fino, onde a lama não alcançou, restando
            as casas aprumadas em condição de degradação, e com sinais de
            remoção forçada de elementos construtivos. Foto: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-start"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_06.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da fachada frontal da ruína de uma casa situada na região de
            Bento onde a lama não alcançou, com sinais de remoção forçada das
            esquadrias. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A situação resultante é da presença da lama sobre os terrenos e
                áreas de uso comum da comunidade, e assim a topografia foi
                alterada, com aumento de altura em relação ao nível natural do
                solo. Sobre a camada de rejeito com superfície irregular,
                encontra-se vegetação invasora, entulho e fragmentos de
                construção, consistindo em condições de risco de acidentes.
                Soma-se a isso a presença de animais peçonhentos como cobras,
                aranhas, que encontram um ambiente favorável para se camuflarem
                e se procriarem.
              </p>
              <p>
                O território de Bento Rodrigues encontra-se sob a ameaça de um
                novo rompimento, integrando a área de estudo de Dam Break da
                barragem de Germano.
              </p>
              <p>
                O rompimento da barragem de Fundão teve consequências
                irreversíveis como a perda de vidas humanas, assim como de
                animais de estimação, de criações relacionadas a atividades
                produtivas. A devastação do território trouxe perda de
                vizinhança, já que a ocupação e uso dos terrenos da comunidade
                ficou inviabilizada; foram perdidos os contatos cotidianos entre
                os moradores da própria comunidade, bem como as relações sociais
                e econômicas com as comunidades próximas a Bento, como é o caso
                de Camargos e Santa Rita Durão. Foram interrompidas as relações
                de confiança entre moradores, em um ambiente antes caracterizado
                pela cooperação mútua e de trocas. As pessoas foram privadas da
                condição de tranquilidade antes vivida, do silêncio, ar puro,
                água pura e abundante, da paisagem do lugar, da segurança de
                deixar as crianças brincarem na rua, de manter a porta aberta
                para que os vizinhos, amigos e parentes chegassem. Boa parte da
                população de Bento teve a perda da comunidade onde nasceu, viveu
                a maior parte do tempo ou por toda vida e em relação a ela foram
                criados fortes laços afetivos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Além da perda de acesso a outras comunidades pela destruição de
                estradas e pontes, os moradores de Bento Rodrigues vivenciam a
                restrição de acesso à própria comunidade, com a implementação de
                uma guarita de controle do acesso a Bento por parte das empresas
                mineradoras.
              </p>
              <p>
                Com a devastação causada pela lama de rejeitos, Bento ficou
                irreconhecível, inclusive com a modificação de parte de seu
                traçado viário, como é o caso das ruas do setor a oeste do
                núcleo urbano, em que na região denominada Cascalho não se
                distingue mais os terrenos da rua. O trecho da Rua Dona Olinda a
                oeste da comunidade desapareceu, sendo abertos caminhos na área
                em outros locais, dificultando o reconhecimento e orientação até
                mesmo para moradores mais antigos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-end"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_07.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de trecho de um muro de pedras que restou em meio à lama na
            Rua Raimundo Muniz. Fonte: Jornal A Sirene, Ed. 10, janeiro de 2017.
            Autor: Genival Pascoal.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Bento sofreu a descaracterização de sua paisagem e a perda de
                referências físicas urbanas. O núcleo possuía uma conformação
                compacta, isto é, com os extremos do subdistrito próximos do
                centro. Foi perdido também seu eixo urbano tradicional de
                referência, a Rua São Bento, balizado pelas duas capelas, a das
                Mercês e a de São Bento. A proximidade entre os templos
                religiosos foi suprimida, condição que possibilitava a
                realização de procissões de modo confortável, já que o peso dos
                andores, por exemplo, era carregado em um curto percurso. Foi
                eliminada a escala urbanística tradicional, composta por vias
                estreitas, com edificações predominantemente térreas ou de
                gabarito baixo, casas construídas com as fachadas sobre a
                testada dos terrenos ou com pequeno afastamento, em um
                território pouco adensado. Foram assoladas edificações civis
                remanescentes do século XVIII, XIX e início do século XX,
                construídas de maneira vernacular, ou seja, utilizando-se
                materiais disponíveis na região. Foram perdidos remanescentes
                arqueológicos como o cocho e muro de pedras do conjunto de
                estruturas do antigo rancho de tropeiros, assim como outros
                muros de pedra existentes nos terrenos particulares.
              </p>
              <p>
                Estes também ficaram desconfigurados, não havendo mais em muitos
                casos as referências físicas como limites e cercamentos. Os
                moradores ficaram impedidos de ocupar e usar seus terrenos, que
                possuíam grandes extensões, onde era possível habitar,
                compartilhar com outros núcleos e realizar as várias atividades
                produtivas, em solo propício para cultivo.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-end"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_08.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de uma residência situada à Rua São Bento, destruída pela ação
            da lama de rejeitos. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-start"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_09.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de casas que restaram aprumadas depois da passagem da lama,
            com indícios de furto pela remoção forçada das esquadrias, entre
            outros elementos construtivos. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Com o desastre criminoso, houve interrupção dos serviços
                públicos básicos como fornecimento de água pela rede municipal
                de abastecimento, fornecimento de energia elétrica proveniente
                da rede pública (de responsabilidade da concessionária CEMIG),
                serviço de coleta de lixo, correio e transporte público (linhas
                de ônibus).
              </p>
              <p>
                O rompimento da barragem de Fundão provocou o abandono
                compulsório da comunidade de Bento Rodrigues, sendo os terrenos
                forçosamente abandonados, havendo o deslocamento obrigatório dos
                moradores de todo o subdistrito. A maior parte das pessoas
                passou a residir na sede de Mariana, em imóveis alugados pelas
                empresas mineradoras, vivenciando um contexto social e urbano
                extremamente diverso do ambiente e do cotidiano que possuíam. A
                sede de Mariana tem quase 60 mil habitantes, 100 vezes mais do
                que havia em Bento Rodrigues.
              </p>
              <p>
                Os moradores de Bento tiveram suas habitações arruinadas ou
                aniquiladas completamente; perderam seus pertences como roupas,
                carros, motos, eletrodomésticos, entre outros. As casas situadas
                em área onde a lama não alcançou, foram se degradando com a ação
                do tempo, das chuvas, e nelas são observados indícios de furto
                pela remoção forçada de janelas, portas, telhado, além do
                desaparecimento de pertences. Com o abalo do impacto da lama no
                território, nas ruínas das casas que restaram aprumadas surgiram
                trincas e rachaduras, não havendo segurança quanto a seu
                desabamento.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-start"
        >
          <img
            max-width="70%"
            src="../../assets/sobre_rompimento_bento_10.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Registro fotográfico do topo de troncos secos de árvores que
            restaram no território. Observa-se em segundo plano o relevo
            montanhoso coberto por mata e áreas de pastagem. Foto: Paula
            Zanardi, 2021.
          </p>
          <img
            max-width="70%"
            src="../../assets/sobre_rompimento_bento_11.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Tronco de árvore seco remanescente em meio à lama acumulada em um
            terreno da comunidade, onde cresce a vegetação invasora. Foto: Paula
            Zanardi, 2021.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Em relação às consequências socioeconômicas, o rompimento da
                barragem ocasionou perda de trabalho e fonte de renda, aumento
                de despesas e endividamento. Muitas famílias tiravam o sustento
                ou parte da renda da comercialização do que era produzido nos
                terrenos. A alimentação era baseada principalmente nos produtos
                do quintal e depois do desastre, tudo que consomem é adquirido
                no mercado a preços altos, além de não haver segurança quanto à
                presença de agrotóxicos e fertilizantes químicos. Havia na
                comunidade pontos comerciais que foram destruídos, e a família
                perdeu além da edificação em si, a atividade econômica.
              </p>
              <p>
                Nos terrenos, foram destruídas as edificações acessórias de uso
                rural como galinheiro, chiqueiro, curral, estábulo, bem como os
                tanques para criação de peixe. As atividades de criação de
                animais realizadas desde a fundação de Bento Rodrigues ficaram
                impedidas como a criação de bovinos e produção de carne, leite e
                seus derivados. Foi interrompida a criação de aves e produção de
                ovos, criação de suínos (porcos), de cavalos e mulas, de peixes,
                de abelhas e o manejo e colheita de mel. A produção era
                realizada tanto para consumo das famílias, quanto para a venda.
                Além das construções de uso rural citadas, foram perdidas outras
                como paiol, depósito e casa de ferramentas.
              </p>
              <p>
                Juntamente com a criação de animais, as atividades de plantio
                eram desenvolvidas em Bento desde o período colonial. O
                rompimento da barragem impediu que fossem continuados o cultivo
                de roças anuais diversificadas, de hortaliças, de árvores
                frutíferas, assim como das flores que eram plantadas nos
                jardins. A lama acabou com as sementes crioulas, sem modificação
                genética e presença de agrotóxicos. Entre os cultivos dos
                terrenos rurais, foram perdidos os plantios de Eucalipto e a
                produção de carvão vegetal, juntamente com os fornos de encosta.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Em relação aos recursos minerais, estavam presentes em vários
                terrenos, cascalho e areia branca, perdidos em meio ao rejeito.
                Soma-se a isso, a perda da atividade de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_aguas' }"
                  >garimpo</router-link
                >
                nos rios da região, a exemplo da extração na comunidade de

                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_bicas' }"
                  >Bicas</router-link
                >, situada a leste do território.
              </p>
              <p>
                O desastre criminoso trouxe ainda danos para a biodiversidade
                local, alcançando áreas de mata nativa, provocando o
                desequilíbrio ecológico, o desaparecimento de animais e plantas
                da região. A comunidade perdeu em seus terrenos nascentes e
                córregos, sendo que a água era abundante favorecendo as
                atividades produtivas. Os rios, afluentes e cachoeiras da região
                foram afetados com a passagem da lama.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-start align-md-end"
        >
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_12.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da ruína da Capela de São Bento, sobre a qual foi executada
            uma cobertura provisória. Visualiza-se parte do embasamento de
            pedras com o reboco impregnado pela lama. À frente da ruína, parte
            do tronco de uma mangueira que foi destruída. Foto: Paula Zanardi,
            2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-start"
        >
          <img
            max-width="70%"
            src="../../assets/sobre_rompimento_bento_13.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista do assoalho remanescente da Capela de São Bento depois de ser
            destruída pela lama. Além do assoalho, restaram trechos do
            embasamento de pedras. Fonte: Jornal A Sirene, Ed. 63, julho de
            2021.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Na comunidade havia associações que também sofreram danos em
                função do rompimento, a exemplo da Associação dos
                Hortigranjeiros de Bento Rodrigues (AHOBERO), que perdeu sua
                sede, o local de produção e os terrenos onde plantava. Houve
                impedimento temporário da produção da geleia de pimenta biquinho
                e da comercialização. Já o plantio foi interrompido, visto que
                não possuem mais terreno para plantar. Quanto à Associação
                Comunitária de Bento Rodrigues, teve sua atuação interrompida
                desde o rompimento da barragem.
              </p>
              <p>
                Em relação aos equipamentos comunitários, em parte houve
                comprometimento do acesso e em sua maioria, foram completamente
                destruídos, como é o caso da Capela de São Bento e seu
                cemitério. A Capela de Nossa Senhora das Mercês teve o acesso
                restrito desde o rompimento, assim como seu cemitério. O templo
                da Igreja Assembleia de Deus também foi danificado e mesmo a
                lama não alcançando as cotas do terreno onde foi edificada, seu
                estado é de arruinamento. A Escola Municipal, o posto de saúde e
                o dos correios foram destruídos pelo rejeito. A Praça Cônego
                Caetano Corrêa também foi destruída, assim como os pontos de
                ônibus e os campos de futebol, restando aprumada a quadra
                poliesportiva em meio ao território arrasado.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_14.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da fachada frontal da ruína da Igreja Assembléia de Deus.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_15.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista de parte das ruínas da escola municipal, a partir da Rua Dona
            Olinda. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_16.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da quadra poliesportiva coberta, que restou aprumada em ponto
            da comunidade que a lama não alcançou. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <img
            max-width="100%"
            src="../../assets/sobre_rompimento_bento_17.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da ruína de parte da edificação onde funcionava o Bar da
            Sandra. Observa-se a lama acumulada sobre o terreno natural Foto:
            Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Foram perdidos os locais de lazer e convívio como o Bar da
                Sandra, Bar do Sobreira, Bar do Juca, as cachoeiras, rios e
                córregos, as matas e trilhas, a atividade de pesca. Outras
                extrações também não continuam, como a coleta de lenha seca nas
                matas, de frutas e ervas medicinais na região.
              </p>
              <p>
                No que diz respeito às manifestações tradicionais, ficaram
                impedidas de serem realizadas da mesma forma de antes do
                rompimento; celebrações e festividades tradicionais como a Festa
                de São Bento, de N. Sra. das Mercês, Festa Junina, os terços nas
                casas da comunidade.
              </p>
            </template>
          </Card>
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Uma das situações mais difíceis que a comunidade enfrenta é em
                relação à contaminação pela presença de metais pesados na lama
                de rejeitos. Tanto em Bento, quanto nas demais comunidades, as
                pessoas convivem com a falta de informações suficientes para
                saber se há contaminação no solo e na água, bem como as
                consequências do contato com a lama e com a poeira que fica no
                ar.
              </p>
              <p>
                As pessoas atingidas passaram a apresentar danos à saúde física
                e mental, e, além da luta diária pela reparação, sofrem com a
                discriminação de parte da população do município de Mariana e de
                outros locais.
              </p>
              <p style="text-align: center;">
                <strong>Autora: Ana Paula Alves Ferreira<sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-center align-md-end"
        >
          <img
            max-width="70%"
            src="../../assets/sobre_rompimento_bento_18.png"
            alt=""
          />
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da ruína da edificação onde funcionava o Bar do Juca, a partir
            da Rua Dr. Carlos Pinto. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> O estudo de “Dam Break”, resumidamente, avalia os
              potenciais impactos da ruptura de uma barragem. Tem por finalidade
              subsidiar a elaboração do “mapa de inundação”, requerido pelo
              órgão fiscalizador (DNPM) para avaliar a categoria de dano
              potencial associado à estrutura na circunstância de ocorrer um
              rompimento da barragem.
            </p>
            <p style="margin-bottom: 0">
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                target="_blank"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
